import { BrowserRouter } from "react-router-dom";


import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import logo from './logo.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Tokenomics } from "./components/Tokenomics";
import Experience from "./components/Experience"


const router = createBrowserRouter([
  {
    path: "/",
    element: <div>
      <NavBar/>
      <Banner/>
      <Projects/>
      <Skills/>
      <Contact/>
      <Footer/>
    </div>,
  },
  {
    path: "tokenomics",
    element: <div>
      <NavBar/>
      <Tokenomics/>
      <Footer/>
    </div>,
  },
  {
    path: "roadmap",
    element: <div>
      <NavBar/>
      <Experience/>
      <Footer/>
    </div>,
  },
  {
    path: "partners",
    element: <div>
      <NavBar/>
      <Skills/>
      <Footer/>
    </div>,
  },
  
  
]);

const App = () => {
  return (
    <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  );
}
export default App;