import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import tokenomics from "../assets/img/tokenomics.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import starbucks from "../assets/img/logo.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Experience from "./Experience"

import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import { styles } from "../styles";

import { SectionWrapper } from "../hoc";
import { textVariant } from "../utils/motion";

export const Projects = () => {

  const projects = [
    {
      title: "🚀 Meme-Driven Prosperity",
      description: "We understand that memes are more than just jokes; they're a global phenomenon, and they have value. With Power Meme Token, you can invest in the memes you love and watch them appreciate in a whole new way.",
      imgUrl: projImg1,
    },
    {
      title: "💣 Blast Off to Virality",
      description: "Buckle up for an exhilarating journey through the meme cosmos! Holding Power Meme Tokens means riding the rocket of viral internet culture, where you can see your investments skyrocket!",
      imgUrl: projImg2,
    },
    {
      title: "🔒 Built on Rock-Solid Blockchain",
      description: "Your financial security is our top priority. Power Meme Token is founded on blockchain technology, ensuring the utmost security and transparency. Our smart contracts make transactions seamless and secure",
      imgUrl: projImg3,
    },
  
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Introducing Power Meme Token: Your Ticket to the Meme Revolution!</h2>
                <p>🌐 In the world of cryptocurrency, Power Meme Token is a game-changer, merging the captivating allure of viral humor with the transformative capabilities of blockchain technology. Here's why Power Meme Token is the ultimate meme enthusiast's dream come true</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Features</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Roadmap</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tokenomics</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Experience/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <img src={tokenomics}></img>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
const experiences = [
  {
    title: "Quarter 1",
    company_name: " Establishing the Foundation ",
    icon: starbucks,
    iconBg: "#383E56",
    date: "",
    points: [
      "Project Launch : Release Power Meme Token on popular decentralized exchanges (DEXs) like Uniswap and PancakeSwap.",
      "Community Building : Establish and nurture a strong and active community on social media platforms, including Discord, Telegram, and Twitter. Encourage user engagement through giveaways, contests, and meme contests.",
      "Audit and Security : Conduct a smart contract audit to ensure the security and integrity of the token. Make necessary updates and improvements based on audit results."
    ],
  },
  {
    title: "Quarter 2",
    company_name: "Enhancing Utility",
    icon: starbucks,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "NFT Integration: Launch the Power NFT platform, allowing users to create, buy, and trade meme-inspired NFTs. Collaborate with popular meme creators to release limited edition NFTs.",
      "Governance Model : Implement a decentralized governance model that allows Power token holders to vote on proposals and project decisions. This enhances community involvement and decentralization.",
      
    ],
  },
  {
    title: "Quarter 3",
    company_name: "Growth and Expansion",
    icon: starbucks,
    iconBg: "#383E56",
    date: "",
    points: [
      "Partnerships and Collaborations : Establish partnerships with other meme token projects, meme creators, and crypto influencers to cross-promote and expand the Power ecosystem.",
      "Token Burns : Initiate a controlled token burn to reduce the token supply and create scarcity, potentially driving up the token's value.",
      
    ],
  },
  {
    title: "Quarter 4",
    company_name: "Long-Term Sustainability",
    icon: starbucks,
    iconBg: "#E6DEDD",
    date: "",
    points: [
      "DApp Development: Begin the development of Power-themed decentralized applications (DApps) to further enhance the utility of the token. Potential DApps could include meme-related games, meme marketplaces, or meme-focused social platforms.",
      "Charity and Philanthropy : Introduce a mechanism for charitable contributions, where a portion of transaction fees or token holdings can be donated to causes or charities chosen by the community.",
      "Continued Community Growth : Continue to actively engage the community and explore avenues for growth, such as more giveaways, meme contests, and educational content.",
      
    ],
  },
];
export { experiences };
