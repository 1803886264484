import meter1 from "../assets/img/bitmart.png";
import meter2 from "../assets/img/MEXC.png";
import meter3 from "../assets/img/gate.png";
import meter4 from "../assets/img/igs.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill " id="skills">
        <div className="container">
            <div className="row ">
                <div className="col-12">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                    <div className="skill-bx wow zoomIn">
                        <h2 className="mt-20 pt-20">Listing & Financial Partners</h2>
                        <p>We believe that partnerships are the cornerstone of progress and prosperity. <br></br> Our commitment to excellence extends beyond the boundaries of innovation, and it's only through the support of our incredible listing and financial partners that we've been able to reach new heights.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>BitMart Exchange</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>Gate IO</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>MEXC</h5>
                            </div>
                            <div className="item">
                                <img src={meter4} alt="Image" />
                                <h5>IGS Trading</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
