import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import tokenomics from "../assets/img/tokenomics.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import starbucks from "../assets/img/logo.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Experience from "./Experience"

import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import { styles } from "../styles";

import { SectionWrapper } from "../hoc";
import { textVariant } from "../utils/motion";

export const Tokenomics = () => {
  const projects = [
    {
      title: "🚀 Meme-Driven Prosperity",
      description: "We understand that memes are more than just jokes; they're a global phenomenon, and they have value. With Power Meme Token, you can invest in the memes you love and watch them appreciate in a whole new way.",
      imgUrl: projImg1,
    },]

  

  return (
    <section className="project" id="projects">
      <Container>
        <Row className="mt-5 pt-5">
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                
                <Tab.Container id="projects-tabs" defaultActiveKey="third">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    
                    
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tokenomics</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                  
      
                  <Tab.Pane eventKey="third">
                  <img className="img-fluid" src={tokenomics}></img>  
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
        
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
      
    </section>
  )
};

